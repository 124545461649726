<template>
  <v-card
    class="aging-summary"
    v-if="account"
  >
    <v-card-actions class="align-start">
      <v-card-title>{{$t('t.Aging')}}</v-card-title>
      <v-spacer />
      <v-btn-toggle
        v-model="mode"
        mandatory
      >
        <v-btn icon small value="bar">
          <v-icon small>{{$icon('i.ChartHistogram')}}</v-icon>
        </v-btn>
        <v-btn icon small value="timeline">
          <v-icon small >{{$icon('i.ChartTimelineVariant')}}</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-card-actions>
    <component
      :is="mode"
      :account="account"
    />
  </v-card>
</template>

<script>
export default {
  components: {
    bar: () => import('./aging-bar'),
    timeline: () => import('./aging-timeline')
  },
  data () {
    return {
      mode: 'bar'
    }
  },
  props: {
    account: Object
  }
}
</script>
